<div class="d-flex flex-column h-100">
  <ca-top-nav [flexibleTopNav]="flexibleMobileNav">
    <ng-content select="[caTopNavSearch]"></ng-content>
  </ca-top-nav>

  <div class="ca-drawer-container">
    <ca-side-nav [navigationMap]="sideNavigationMap$ | async" [flexibleSideNav]="flexibleMobileNav"></ca-side-nav>
    <ng-container #mobileDrawerContainer>
      <div
        class="ca-content-wrapper"
        [class.overflow-hidden]="!(changedScrollEnabled$ | async)"
        #updatedScrollableElement
      >
        <ng-container #desktopDrawerContainer>
          <ca-breadcrumbs></ca-breadcrumbs>
          <div
            class="ca-content mt-n1 pt-1"
            [class.overflow-auto]="!(changedScrollEnabled$ | async)"
            #scrollableElement
          >
            <ng-content></ng-content>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
