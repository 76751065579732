import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavigationItemsService, SideNavigationMap } from '@capital-access/common/navigation';
import {
  Breakpoint,
  FireflyDrawerDefaultContainerDirective,
  FireflyDrawerService
} from '@capital-access/firefly/components';

@Component({
  selector: 'ca-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollableElement') scrollableElement!: ElementRef;
  // should be removed together with mobile-scroll-behavior-CPD-2028
  @ViewChild('updatedScrollableElement') updatedScrollableElement!: ElementRef;
  @ViewChild('desktopDrawerContainer', { read: ViewContainerRef, static: true })
  desktopDrawerContainer!: ViewContainerRef;
  @ViewChild('mobileDrawerContainer', { read: ViewContainerRef, static: true })
  mobileDrawerContainer!: ViewContainerRef;

  flexibleMobileNav = true;

  sideNavigationMap$: Observable<SideNavigationMap>;
  private destroyed$ = new Subject<void>();
  changedScrollEnabled$ = this.store.select(getFeatureToggle('mobile-scroll-behavior-CPD-2028'));

  constructor(
    navigationItemsService: NavigationItemsService,
    private store: Store,
    private router: Router,
    private renderer: Renderer2,
    private drawerService: FireflyDrawerService
  ) {
    this.sideNavigationMap$ = navigationItemsService.getSideNavigationMap();
  }

  get mobile() {
    return window.innerWidth < Breakpoint.Sm;
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this.changedScrollEnabled$),
        tap(([, enabled]) =>
          this.renderer.setProperty(
            (enabled && this.mobile ? this.updatedScrollableElement : this.scrollableElement).nativeElement,
            'scrollTop',
            0
          )
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    const container = this.mobile ? this.mobileDrawerContainer : this.desktopDrawerContainer;
    if (container) new FireflyDrawerDefaultContainerDirective(container, this.drawerService, this.renderer);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
